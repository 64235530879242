







































































import Vue from "vue";
export default Vue.extend({
  name: "AppAccessDeniedComponent",
  props: {
    title: {
      type: String
    },
    camera_and_microphone: {
      type: Boolean
    }
  }
});
